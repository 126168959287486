.section {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: fit-content;
  }

  
  
  h2 {
    font-size: 18px;
    color: #002554;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .section li {
    font-size: 14px;
    color: white;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #002554;
    border-radius: 5px;
  }

 .section li:hover{
    background-color: white;
    color: #002554;
  }
  
  .tableClass {
    margin-bottom: 1vw;
  }
  
  .widthTables {
    max-width: 100%;
    overflow-x: auto;
  }

  .header{
    display: flex;
    justify-content: center;
    
}

.header h4{
    color: #002554;
}
.flexDisp{
    display:flex;
    max-height: 100%;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    overflow: auto;
    margin-left: 1vw;
    
}

.print{
    font-size:  clamp(0.5rem, 0.7vw, 3rem);
    margin-right: 0vw;
    position: absolute;
    right: 7vw;
    box-shadow: none;

}

.dataViz{
    font-size:  clamp(0.5rem, 0.7vw, 3rem);
    margin-right: 1vw;
    position: absolute;
    right: 14vw;
    box-shadow: none;

}
.resultLoader{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 80vw;
    margin-left: 2vw;
    height: 85vh;
    box-sizing: border-box;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

.resultLoader h4{
    color: black;
    font-size: 1vw;
}

.loadRes{
    width: 100%;
  background-color: rgba(0, 37, 84, 0.1) ;
  display: flex;
  flex-direction: column;

}
.hoverInfo{
  display: none;
}


.infoIcon:hover + .hoverInfo{
  display: block;
  position: absolute;
  background-color: #002554;
  color: white;
  max-width: 50%;
  border-radius: 5%;
  left: 30%;
  bottom: 90vh;
}
  
  