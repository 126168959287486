.metricLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100vw;
    box-sizing: border-box;
    margin-top: 0.5vw;
}



.loadMets{
    width: 100%;
    height: 99%;

  display: flex;
  flex-direction: column;
  

}

.categories{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 8vw;
    justify-content: space-between;
    transition: opacity 2s;
    text-align: center;
    align-content: center;
    margin-left: 1vw;
    margin-top: 0.5vw;
    
}

.categories *{
    align-content: right;
    justify-content: right;
}

.category{
    width: 100%;
    height: fit-content;
    min-width: 30%;
    box-sizing: border-box;
    font-size: clamp(0.5rem, 1vw, 10rem);
    font-weight: bold;
    cursor: pointer;
    color: white;
    border-radius: 10%;


    padding: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-transform: capitalize;
    flex-wrap: wrap;

}

.category *{
    align-self: center;
}

.category:hover{
    color: #ad8b61;
    transform: scale(1.1);
}

.metricList{
    display: flex;

    min-width: 20%;
    margin-top: 0vh;
    justify-content: center;
    max-height: 100%;
    text-align: center;
    
}

.metricList *{
    margin: 0px;
   
}

.flexForms{
    display: flex;
    justify-content: center;
    height: 70vh;
    overflow-y: auto;
    border-top: 0.1px solid lightgrey;
    
}

.formFlex{
    display: flex;
    flex-direction: column;
    min-width:20%;
    margin: 0.2vw;
    max-height: 100%;
    margin-top: 1vh;
    
    
    
}

.flexDiv{
    display: flex;
    justify-content: center;
}

.halfSize{
    width: 60%;
}




.formFlex h5{
    font-size: clamp(0.5rem, 0.9vw, 5rem);
    position: fixed;
    z-index: 999;
}

.hoverInfo{
    display: none;
}

.infoIcon{
    margin-top: 10%;
}

.infoIcon:hover + .hoverInfo{
    display: block;
    position: absolute;
    background-color: transparent;
    color: #002254;
    max-width: 50%;
    border-radius: 5%;
    left: 30%;
    top: 0;
}


.dataDiv{
    box-sizing: border-box;
    height: 0.5vw;
    padding-bottom: 4vw;
    max-width: 100%;
    margin-top: clamp(20px, 0.5vw, 100px);
}

.dataDivRed{
    box-sizing: border-box;
    height: 0.5vw;
    padding-bottom: 4vw;
    max-width: 100%;
    margin-top: clamp(20px, 0.5vw, 100px);
    background-color: rgba(255,0,0,0.2);
}
.dataDivGreen{
    box-sizing: border-box;
    height: 0.5vw;
    padding-bottom: 4vw;
    max-width: 100%;
    margin-top: clamp(20px, 0.5vw, 100px);
    background-color: rgba(0,128,0,0.2);
}


.third{
    max-width: 100%;
}

.combo{
    max-width: 25%;
    text-align: center;
}

.combo *{
    text-align: center;
}


.arrowFlex{
    display: flex;
    justify-content: center;
    font-size: clamp(0.5rem, 1vw, 5rem);
}

.arrow{
    margin-top: 1vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    cursor: pointer;

}

.overlay{
    font-size: clamp(0.4rem, 0.6vw, 5rem);
}

.overlay *{
    margin-left: 1px;

}
.monthLabels{
    margin: 0px;
    
}

.redError{
    color: red;
}

.yesFlex{
    display: flex;
    justify-content: space-between;
}

.MuiFormHelperText-root{
    font-size: clamp(0.5rem, 0.7vw, 5rem) !important;
}
.MuiInputAdornment-root{
    font-size: clamp(0.5rem, 0.7vw, 5rem) !important;
}
.css-1pnmrwp-MuiTypography-root{
    font-size: clamp(0.5rem, 0.7vw, 5rem) !important;
}
.css-ahj2mt-MuiTypography-root{
    font-size: clamp(0.5rem, 1vw, 5rem) !important;
}
.css-u4tvz2-MuiFormLabel-root{
    font-size: clamp(0.5rem, 1vw, 5rem) !important;
}
.css-1hbvpl3-MuiSvgIcon-root{
    width: (10px, 2vw, 300px);


}
.css-i4bv87-MuiSvgIcon-root{
    font-size: clamp(1rem, 1.5vw, 5rem) !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root{
    padding: 0px;
    margin-left: 0px;
}

.MuiFormLabel-root{
    font-size: clamp(0.5rem, 1vw, 5rem) !important;
}

.MuiFormControlLabel-root *{
    font-size: clamp(0.5rem, 1vw, 5rem) !important;
}

.metricLeft{
    margin-right: 5vw;
    display: flex;
}

.rcaIncomplete{
    margin-left: 5vw;
    display: flex;
}


.centerThis{
    display: flex;
    justify-content: center;
}
.plantName{
    display: flex;
    flex-direction: column;
}

.plantName *{
    margin: 0;
    padding: 0;
}

.loadWalk{
    position: absolute;
    animation: randomWalk 7s infinite;
    z-index: 1;
}

.flexCatTwo{
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .categoryTwo{
    height: fit-content;
    box-sizing: border-box;
    font-size: clamp(0.5rem, 1vw, 10rem);
    font-weight: bold;
    cursor: pointer;
    color: white;
    border-radius: 10%;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-transform: capitalize;
    flex-wrap: wrap;
  
  }
  
  .categoryTwo *{
    align-self: center;
  }

  .formHeaderSect{
    display: flex;
    flex-direction: column;
  }

  .labsNav1{
    display: flex;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    max-height: 7vh;
    background-color: transparent;
    justify-content: space-between;
    color: #002554;
}

.labsNav1 .linkFlex{
    color: #002254;
}

.cubeLeftTwo {
    animation: randomWalkOut 3s;
    animation-iteration-count: 1;
    mix-blend-mode:luminosity;
    z-index: 1;
    position: fixed;
    bottom: 0;
    right: 2vw;
  }

  .dateFixMet{
    position: absolute;
    top: 11vh;
    left: 15vw;
    font-size: 1vw;
  }

  .overlayNew {
    position: fixed;
    width: 80%;
    height: fit-content;
    background:rgba(0, 34, 84, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    margin-top: 50vh;
    margin-left: 10%;
  }
  
  .close-buttonNew {
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 12px;
    box-shadow: none;
  }
  


  

  
  @keyframes randomWalkOut {
    0% {
      transform: translate3d(0, 0, 0);
    }
    25% {
      transform: translate3d(100vw, 0, 0);
    }
    50% {
      transform: translate3d(0, 100vh, 0);
    }
    75% {
      transform: translate3d(-100vw, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  



/*media queries */
@media (max-width:770px) {
    .category *{
      min-width: 40%;
    }
    
  }

  @media (max-width:800px) {
    .metricLoader{
      height: 90vh;
    }
    
  }

  @media (max-width:690px) {
    .metricLoader {
      width: 100vw;
    }
    
  }

  @media (max-width:500px) {
    .MuiFormHelperText-root{
        font-size: 0vw !important;
        display: none;
    }
    .dataDiv{
        margin-bottom: 9vw;
    }
    
  }

  @media (max-width:453px) {
    .goal, .prevMth {
      display: none;
    }
    .third{
        width: 0%;
    }
    .dataDiv{
        margin-bottom: 9vw;
    }
   
    
  }

  @media (max-width:630px) {
    .overlay{
        display: none;
    }
    
  }

  @media(min-height: 1440px){
    .dateFixMet{
        top: 70px;
    }
}

  @media(max-width: 1500px){
    .dateFixMet{
        top: 9vh;
    }

    .dateFixOne{
        top: 9vh;
    }



  }
  @media(max-width: 1000px){
    .stoplight-light{
        height: 70px;
        width: 70px;
    }


  }

  @media(max-width: 850px){
    .stoplight-light{
        height: 60px;
        width: 60px;
    }
    .dateFixMet{
        left: 10vw;
    }
    .dateFixOne{
        left: 10vw;

    }


  }

  @media(max-width: 690px){
    .dateFixMet{
        top: 5vh;
    }
    .dateFixOne{
        top: 5vh;

    }



  }

  @media(max-width: 600px){
    .dateFixMet{
        left: 2vw;
        font-size: 1.5vw;
    }
    .dateFixOne{
        left: 2vw;
        font-size: 1.5vw;

    }
  
  }

  @media(max-width: 400px){
    .dateFixMet{
        top: 80vh;
    }
    .dateFixOne{
        top: 80vh;

    }
  
  }

  @media(max-width: 600px){
    .cubeLeftTwo{
      display: none;
    }
  
  }
