.sideBar{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    opacity: 1;
    margin-left: 5vw;
    text-align: center;
    box-sizing: border-box;
}

.sideBarTitle{
    color: #002554;

    font-size: clamp(1rem, 2vw, 10rem);
}

.sideDiv{
    margin-top: 1vw;
    max-height: 15vh;
  border: 1px solid #002554;
  border-radius: 10%;
  background: rgba(0, 37, 84, 0.1) ;
  padding: 2vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sideDiv *{
    vertical-align: middle;
    text-align: center;
    align-content: center;
    align-self: center;
    font-size: clamp(0.5rem, 1vw, 4rem);
}
.sideBar h4{
    font-size: clamp(0.5rem, 1vw, 4rem);
}

.check{
    text-align: right;
    align-self: center;
    font-size: 5vh;
}

.linkedSideDiv{
    cursor: pointer;
}

.linkedSideDiv:hover{
    background-color: #002554;
    color: white;
}

.divStyle{
    background-color: #002554;
    color: white;
    cursor: not-allowed;
}

.linkSide{
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    color: #002554;
}

.cubeDiv{
    width: 50%;
    margin-left: 1vw;
    margin-top: 2vw;
}

@media (max-width: 1000px){
    .sideCubeFont {
        display: none;

    }
}

.different{
    position: absolute;
    top: 15vh;
    left: 15vw;
    border: none;
    display: flex;
    flex-direction: column;

}

@media(min-height: 1440px){
    .different{
        top: 110px;
    }
}

@media(max-width: 1500px){
    .different{
        top: 12vh;
    }

}

@media(max-width: 1000px){
    .different{
        top: 11vh;
    }

}

@media(max-width: 850px){
    .different{
        left: 10vw;
    }

}

@media(max-width: 690px){
    .different{
        top: 7vh;
    }

}

@media(max-width: 570px){
    .different{
        left: 2vw;
    }

}