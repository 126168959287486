@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap");


.cubeLeftOne {
    animation: randomWalkOut 3s;
    animation-iteration-count: 1;
    mix-blend-mode:luminosity;
    z-index: 1;
    position: fixed;
    bottom: 0;
    right: 2vw;
  }


  .dateFixOne{
    position: absolute;
    top: 11vh;
    left: 15vw;
    color: #fff;
    font-size: 1vw;
  }

  
  
  

  .flip-card {
  backdrop-filter: blur(100px); /* Apply a 5px blur effect to the background */
  -webkit-backdrop-filter: blur(5px); /* Safari support */
    width: 15vw;
    height: 20vh;
    perspective: 1000px;
    margin: 0.3vw;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: rgba(255, 255, 255, 0.1); /* Set a translucent white background color */
  backdrop-filter: blur(100px); /* Apply a 5px blur effect to the background */
  -webkit-backdrop-filter: blur(5px); /* Safari support */
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1vw solid #898F9C;
  }
  
  .flip-card-back {
    background-color: rgba(255, 255, 255, 0.1); /* Set a translucent white background color */
  backdrop-filter: blur(100px); /* Apply a 5px blur effect to the background */
  -webkit-backdrop-filter: blur(5px); /* Safari support */
    transform: rotateY(180deg);
    border-radius: 10%;
    align-items: center;
  }

  .recharts-cartesian-axis-tick-line {
    stroke: #fff;
  }
  
  .recharts-cartesian-axis-tick {
    fill: #fff;
  }
  
  .recharts-bar-rectangle {
    fill: transparent;
  }
  

  
.KPI-container {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}


.flex{
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    
}

.flex h3{
    font-size: 1.5vw;
}

.flex h7{
  font-size: 0.7vw;
}

.flex h4{
  font-size: 1vw;
}

.flipper{
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(270deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

h4{
  text-align: center;
}

.flexBox{
    flex-basis: 20%;
    box-sizing: border-box;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    width: 17vw;
    height: 20vh;
    justify-content: center;
    text-align: center;
    margin-top: 1vh;
    margin-left: 0.5vw;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.1); /* Set a translucent white background color */
  backdrop-filter: blur(100px); /* Apply a 5px blur effect to the background */
  -webkit-backdrop-filter: blur(5px); /* Safari support */
  cursor: pointer;
}

.flexBox h3{
  font-size: 1.7vw;
}

.flexBox h4{
  font-size: 0.9vw;
}

.cubeLeft {
  position: absolute;
  animation: randomWalk 10s infinite;
  mix-blend-mode:luminosity;
  z-index: 1;
}

.helpNav{
  color: #fff;
  margin-top: 1vw;
  font-size: clamp(0.5rem, 1vw, 5rem);
  text-align: center;
    align-self: right;
    vertical-align: text-bottom;
    color: #fff;
    padding: 0px;
    max-width: 20%;
    margin-right: 2vw;
    margin-top: 1vw;
    font-size: clamp(0.5rem, 1vw, 5rem);
}



@keyframes randomWalkOut {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(100vw, 0, 0);
  }
  50% {
    transform: translate3d(0, 100vh, 0);
  }
  75% {
    transform: translate3d(-100vw, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}


.onePage{
    background-image: linear-gradient(to bottom right, #002554, #002554, #002554);
    min-height: 100vh;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.labsNav{
    display: flex;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    max-height: 7vh;
    background-color: transparent;
    justify-content: space-between;
    color: white;
}




.dateFix{
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 0.5vw;
}

.stoplight-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 20px; /* Adjust this value as needed */
}

.stoplight-light {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: background-color 1s;
}

.stoplight-label {
  font-weight: bold;
  margin-top:40%;
  font-size: 9px;
  text-align: center;
  color: #002554;
}

.stoplight-container {
  position: fixed;
  top: 70px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
}

.stoplight-info {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Add margin to create spacing between circle and label */
}

.flexDirCol{
  display: flex;
  flex-direction: column;
  max-height: 80%;
  font-size: 0.9vw;
}

.categoryOne{
  height: fit-content;
  box-sizing: border-box;
  font-size: clamp(0.5rem, 1vw, 10rem);
  font-weight: bold;
  cursor: pointer;
  color: white;
  border-radius: 10%;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-transform: capitalize;
  flex-wrap: wrap;

}

.categoryOne *{
  align-self: center;
}

.overlay{
  font-size: clamp(0.4rem, 0.6vw, 5rem);
}

.overlay *{
  margin-left: 1px;

}
.flexCatOne{
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}

.linkFlex{
  color: #fff;
}

@media (max-width:850px) {
  .flip-card h4{
    font-size: 0.5vw;
  }
  .flip-card{
    height: 15vh;
  }
  
}

@media (max-width:700px) {
  .flip-card h4{
    font-size: 0.5vw;
  }
  .flip-card{
    height: 10vh;
  }
  .stoplight-light {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 1s;
    font-size: 5px;
  }
  .plantSnap *{
    font-size: 2vw;
  }
  
}

@media (max-width: 800px) {
  @keyframes randomWalk {
    0% {
      transform: translate3d(0, 0, 0);
    }
    25% {
      transform: translate3d(25vw, 50vh, 0);
    }
    50% {
      transform: translate3d(50vw, 50vh, 0);
    }
    75% {
      transform: translate3d(-50vw, 50vh, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  
}

@media (max-width: 690px) {
  .stoplight-container {
    top: 3vh;
  }

}
@media(max-width: 600px){
  .different{
      left: 1vw;
  }
  .flip-card-back{
    display: none;
  }

}

@media(min-height: 1440px){
  .dateFixOne{
      top: 70px;
  }
}

@media(max-width: 500px){
  .stoplight-container{
      top: 78vh;
      left: 50vw;

  }

}

@media(max-width: 400px){
  .different{
      top: 82vh;
  }

}

@media(max-width: 700px){
  .dateFixOne{
    font-size: 1vw;
  }

}
@media(max-width: 600px){
  .cubeLeftOne{
    display: none;
  }

}

@media(max-width: 550px){
  .dateFixOne{
    font-size: 1.5vw;
  }
  .flip-card{
    width: 25vw;
  }
  .flip-card h4{
    font-size: 1.5vw;
  }
  .flip-card h3{
    font-size: 2vw;
  }
  .flex h7{
    font-size: 1vw;
  }

}

.metricDataFlip{
  font-size: 1.2vw;
}

