.sideCubeFont{
    text-align: center;
    align-self: center;
    font-size: 15px;
  }

  .cotyKPIs{
    display: flex;
    justify-content: center;
    margin-right: 7vw;
  }

  .secondChar{
    color: white;
  }

  .firstChar{
    color: rgb(50 245 169);
  }

  @media only screen and (min-width: 600px) {

    .cotyKPIs{
      font-size: 45px;


    }


  }
 