.plantComp{
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 0vh;
    font-size: clamp(0.5rem, 1vw, 4rem);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 10%;
    padding: 1vw;
}

.passProp{
    display: none;
}

.selectOptions{
    width: max-content;
    font-size: clamp(0.5rem, 0.7vw, 1000px);
}

