.side-cube-container {
    perspective: 800px;
    width: 5vw;
    height: 5vw;
    margin: 0.5vw auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .side-cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation: spin 10s infinite linear;
  }
  
  .side-cube-face {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0,37,84, 0.7);
    border: 0.1vw solid white;
    font-size: clamp(0.5rem, 0.7vw, 30rem);
    color: white;
    text-align: center;
    
  }
  
  .side-cube-face-front {
    transform: translateZ(2.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .side-cube-face-back {
    transform: translateZ(-2.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  

  
  .side-cube-face-left {
    transform: rotateY(-90deg) translateZ(2.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-right {
    transform: rotateY(90deg) translateZ(2.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-top {
    transform: rotateX(90deg) translateZ(2.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-bottom {
    transform: rotateX(-90deg) translateZ(2.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  
  @keyframes spin {
    from {
      transform: rotateY(0deg) rotateX(0deg);
    }
    to {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }
  