.navFlex{
    display: flex;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    max-height: 10vh;
    justify-content: space-between;
    color: #002554;
    
}

.navFlex h1{
    font-size: clamp(1rem, 2vw, 10rem);
}
.navFlex h2{
    font-size: clamp(1rem, 2vw, 10rem);
}
.logoContain{
    max-width: 100%;

}
.navFlex h1{
    margin-left: 1vw;
}

.linkFlexNavi{
    text-decoration: none;
    text-align: center;
    color: #002554;
  }



.help{
    text-align: center;
    align-self: right;
    vertical-align: text-bottom;
    color: #002554;
    padding: 0px;
    max-width: 20%;
    margin-right: 2vw;
    margin-top: 1vw;
    font-size: clamp(0.5rem, 1vw, 5rem);
}

.hamburger{
    display: none;
}

.overlayNav{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 50px;
    background-color: #002554;
    text-align: center;
    padding: 1vw;
    transition: ease-in;

    
}
.overlayNav *{
    margin-bottom: 3vw;
    padding: 0px
}
.optionPage{
    border: 2px solid white;
    margin-bottom: 7vw;
}

.close{
    text-align: right;
}

.muiButtons{
    display: flex;
    justify-content: center;
    text-align: center;
}

.muiButtons *{
    margin-left: 0.3vw;
}



.change{
    cursor: pointer;
}

.contactUs{
    cursor: pointer;
}
.change a{
    color: inherit;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
}

.homeMui a{
    color: inherit;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
}

  

@media(max-width: 690px){
    .logoContain{
        display: none;
      }
}

@media (max-width:595px) {
    .logoContain{
      display: none;
    }
    .welcomeUser{
        display: none;
    }
    
  }

