.side-cube-container-mobile {
    perspective: 800px;
    width: 80px;
    height: 80px;
    margin: 0.5vw auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .side-cube-mobile {
    width: 80px;
    height: 80px;
    position: relative;
    transform-style: preserve-3d;
    animation: spin 10s infinite linear;
  }
  
  .side-cube-face-mobile {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: rgba(50, 245, 169, 0.3);
    border: 0.1vw solid white;
    font-size: clamp(0.5rem, 0.7vw, 30rem);
    color: white;
    text-align: center;
    
  }
  
  .side-cube-face-front-mobile {
    transform: translateZ(40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-back-mobile {
    transform: translateZ(-40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  .side-cube-face-left-mobile {
    transform: rotateY(-90deg) translateZ(40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-right-mobile {
    transform: rotateY(90deg) translateZ(40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-top-mobile {
    transform: rotateX(90deg) translateZ(40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .side-cube-face-bottom-mobile {
    transform: rotateX(-90deg) translateZ(40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  
  @keyframes spin {
    from {
      transform: rotateY(0deg) rotateX(0deg);
    }
    to {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }
  