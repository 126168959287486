body{
  background: rgba(0, 37, 84, 0.1) ;
  
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color:#002554;
    
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  } 

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

 

  .homeFlex, .homeFlex2{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 2vw;
    margin-top: 0.5vw;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    flex-wrap: wrap;
    
    
  }

  .homeFlex2{
    margin-top: 1vw;
  }



  .mainFlex img{
    max-width: clamp(250px, 40%, 500px);
    height: auto;
    transform: translateZ(50px) scale(1.1);
    transition: transform 0.3s ease-out;
    
    
  }
  

  .mainFlex{
    display: flex;
    justify-content: center;
    opacity: 0.8;
    margin-bottom: 1vh;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    flex-wrap: wrap;
    text-align: center;
    
  }

  .mainFlex h1{
    color: #002554;
    font-size: clamp(0.5rem, 1.5vw, 5rem);
    padding: 0.1vw;
    font-style: italic;

  }

button{
    padding: 0.5vw 0.5vw;
    border-radius: 10%;
    color: white;
    background-color: #002554;
    cursor: pointer;
    border: 0.2vw solid black;
    background: 
    linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.3) 50%,#0000 66%)
    #002554;
  background-size:300% 100%;
  animation: shine 2s infinite;
  width: fit-content;
  font-size: clamp(0.5rem, 1vw, 4rem);
  box-shadow: 0vw 1vw 1vw 0vw grey;
}

.plantPick h4{
  font-size: clamp(0.7rem, 1vw, 4rem);;
}

@keyframes shine {
  0% {
    background-position: right;    
  }
  /*100% {
    background-position: left; it's the default value, no need to define it
  }*/
  }

button:hover{
    background-color: #ad8b61;
    color: #002554;
    font-weight: bold;
    border: 3px solid #002554;
  }
.popUp{
  opacity: 0;
  font-size: clamp(0.5rem, 0.5vw, 4rem);
  display: flex;
  padding: 1vw 2vw;
  text-align: center;
  justify-content: space-evenly;
  max-width: 15vw;
  border: 1px solid #002554;
  border-radius: 10%;
  background-color: rgb(0,37,84, 0.2);
  flex-direction: column;
  align-items: center;
  
}

.popup *{
  align-self: center;
  text-align: center;
}

.popUp1{
  animation: appear 3s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  transition: ease-in;
  
}

.popUp2{
  animation: appear 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transition: ease-in;
  margin-left: 2vw;
  margin-right: 2vw;
}

.popUp3{
  animation: appear 3s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  transition: ease-in;
}

.popUp4{
  animation: appear 3s;
  animation-delay: 9.5s;
  animation-fill-mode: forwards;
  transition: ease-in;
}

.popUp5{
  animation: appear 3s;
  animation-delay: 12.5s;
  animation-fill-mode: forwards;
  transition: ease-in;
}

.popUp6{
  animation: appear 3s;
  animation-delay: 15.5s;
  animation-fill-mode: forwards;
  transition: ease-in;
}

@keyframes appear{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.plantPick{
  display: flex;
  justify-content: center;
}

.linkFlex{
  text-decoration: none;
  text-align: center;
}

.linkFlex button{
  max-width: 100%;
  margin-bottom: 1vh;
}

/*media queries*/
@media(max-width: 970px){
  .cubeFont{
    font-size: 1vw;
  }
}
@media (max-width:820px) {
  .homeFlex2{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .homeFlex2 *{
    margin-bottom: 1vw;
  }
  
}

@media (max-width:630px) {
  .homeFlex2{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .popUp{
    border: none;
    background-color: rgb(0,0,0, 0);;
  }
  .cubeFont{
    display: none;
  }
  
}


