.kpiPage{
    background-color: rgba(4,6,31,1) ;
    top:0;
    left: 0;
    min-height: 100%;
    width: 100%;
    position: absolute;
}



::-webkit-scrollbar-thumb {
    background-color: rgba(50, 245, 169, 1);  /* color of the scroll thumb */
    border-radius: 6px;  /* roundness of the scroll thumb */
    border: 2px solid rgba(50, 245, 169, 1);  /* creates padding around scroll thumb */
  }