.typing-container {
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    color: rgb(50 245 169);
    margin-top: 0px;
  }
  
  .cursor {
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  @media only screen and (min-width: 600px) {
    .typing-container{
      font-size: 30px;
    }


  }
  
  