.cube-container {
    perspective: 800px;
    width: clamp(10px, 9vw, 500px);
    height: clamp(10px, 9vw, 500px);
    margin: 1vw auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation: spin 10s infinite linear;
  }
  
  .cube-face {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0,37,84, 0.7);
    border: 0.2vw solid white;
    font-size: clamp(1rem, 1.5vw, 30rem);
    color: white;
    text-align: center;
    
  }
  
  .cube-face-front {
    transform: translateZ(4.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cube-face-back {
    transform: translateZ(-4.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  

  
  .cube-face-left {
    transform: rotateY(-90deg) translateZ(4.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cube-face-right {
    transform: rotateY(90deg) translateZ(4.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cube-face-top {
    transform: rotateX(90deg) translateZ(4.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cube-face-bottom {
    transform: rotateX(-90deg) translateZ(4.5vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  
  @keyframes spin {
    from {
      transform: rotateY(0deg) rotateX(0deg);
    }
    to {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }
  