.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    margin-top: 0px;
  }

  .toggleBack{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }

  .fade {
    transition: opacity 1s, visibility 1s; /* Adjust the 0.5s to your desired transition duration */
  }
  
  .fade-out {
    opacity: 0;
    visibility: hidden;
    height: 0px;
    width: 0px;
    display: flex;
  }
  
  .fade-in {
    opacity: 1;
    visibility: visible;
  }
  

  
  
 .place{
    color: white;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
 }
 .typeMessage{
    display: flex;
    justify-content: center;
 }

 .typeMessage *{
    margin-left: 5px;
 }

 .selectAPlant{
    color: white;
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-bottom: 0px;
 }

 .placeHolder{
    color: rgb(50 245 169);
    text-align: center;
 }

 .imgFlex{
    display: flex;
    justify-content: center;
    margin-top: 2vh;
 }

 .img{
    height: 100px;
    width: 100px;
    margin-left: 2vw;
    margin-right: 2vw;
    align-self: center;
    text-align: center;
    animation: spinAndRotate 20s linear infinite;
  
 }



.category-section {
  margin-bottom: 20px;
  text-align: center;
}

.metrics-list {
  display: flex;
  overflow-x: auto; /* Allow vertical scrolling */
  height: 100px; /* Or adjust as per your requirement */
  text-align: left;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.metrics-list::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for WebKit */
}

.metric-item {
  background-color: rgba(50, 245, 169, 0.5); /* This gives the see-through effect */
  padding: 10px;
  margin: 5px;
  border-radius: 10%;
  min-width: 20%;
  max-width: 20%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  
  
}

.metric-item::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for WebKit */
}
.metric-value{
  font-weight: bold;
  text-align: center;
  margin-bottom: 2px;
  font-size: 14px;
}
.metric-name {
  font-size: 11px;
  text-align: center;
}

.plantTitleFlex{
  display: flex;
  justify-content: space-between;
}

.plantNameProfile{
  color: rgb(50, 245, 169);
  font-size: 20px;
  margin-right: 3vw;
}

.overlay-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content-mobile {
  background: rgba(50,245,169,0.9);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.overlay-content-mobile *{
  margin-left: 10px;
}

.close-mobile {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
}

.check-mark {
  color: green;
}

.cross-mark {
  color: red;
}





 @keyframes spinAndRotate {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}



 .region{
   color: rgb(50, 245, 169);
   font-size: 12px;
   text-align: center;
   cursor: pointer;
 }

 .plantProfileShow{
  color: white;
  display: flex;
  flex-direction: column;
 }

 .plantItemHero {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center;     /* vertically center */
  background-color: rgba(50, 245, 169, 0.3);
  color: white;
  border-radius: 50%;      /* makes it a circle */
  width: 60px;
  height: 60px;
  margin-right: 10px;
  font-weight: bold;
  flex-shrink: 0;          /* ensures it doesn't shrink in a flex container */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(50, 245, 169, 1);  /* color of the scroll thumb */
  border-radius: 6px;  /* roundness of the scroll thumb */
  border: 2px solid rgba(50, 245, 169, 1);  /* creates padding around scroll thumb */
}

.loadText{
  color: rgba(50, 245, 169, 1);
  font-size: 24px;
}

.regionProfile{

  margin-left: 15vw;
}

.regionItemHero {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center;     /* vertically center */
  color: white;
  border-radius: 50%;      /* makes it a circle */
  width: 60px;
  height: 60px;
  margin-right: 10px;
  font-weight: bold;
  flex-shrink: 0;          /* ensures it doesn't shrink in a flex container */
}





/* Media queries */
  
 @media only screen and (min-width: 600px) {
   .imgFlex{
      display: flex;
      justify-content: center;
      margin-top: 2vh;
   }
   .metrics-list::-webkit-scrollbar {
    width: 4px; /* Vertical scrollbar width */
    height: 4px; /* Horizontal scrollbar height */
    display: block;
}
.plant-list{
  max-width: 50%;
}
.img{
  width: 200px;
  height: 200px;
}

.plantItem{
  width: 100px;
  height: 100px;
}
.place{
  font-size: 30px;
}

.metrics-list{
  width: 45%;
}

.metrics-list::-webkit-scrollbar {
  width: 5px;  /* Set the width of the scrollbar */
}

.metrics-list::-webkit-scrollbar-track {
  background: #F0F0F0;  /* Set the track/background color */
}

.metrics-list::-webkit-scrollbar-thumb {
  background: #90EE90;  /* Light green color for the scrollbar handle/thumb */
}

.metrics-list::-webkit-scrollbar-thumb:hover {
  background: #7CCD7C;  /* Slightly darker green when hovering over the scrollbar thumb */
}

  
}


