.footer{
    background-color: rgba(0, 37, 84, 1);
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    position: absolute;
    bottom: 0;
    margin-bottom: 0.5vw;
    display: flex;
    color: white;

    display: flex;
}

.footer *{
    vertical-align: top;
    font-size: clamp(0.5rem, 0.7vw, 3rem);
    margin: 0px;
}

