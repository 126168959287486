.headerMobileContainer{
    margin-top: 1.5vh;
    height: fit-content;
    display: flex;
    background-color: rgb(1, 2, 21) ;
    justify-content: center;
}
.headerMobileHamburger{
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    margin-right: auto;
    margin-left: 1vw;
}
.bar {
    background-color:rgb(50 245 169);
    height: 3px;
    width: 25px;
    margin: 4px 0;
}

.headerMobileLogo {
    font-family: 'Arial', sans-serif;
    font-size: 24px;

    padding: 10px 20px;
    border-radius: 5px;
    
  
  }
  .mobileLogoTitle{
    color: rgb(50 245 169);
    font-size: 30px;
    
  }
  
  .mobileMetric {
    font-weight: bold;
    color: #fff;  /* A green shade for emphasis */
    font-size: 30px;
  }
  
  .beta {
    font-size: 14px;
    margin-left: 5px;
    vertical-align: super;
    color:rgb(50 245 169);  /* A red shade for the beta symbol */
  }
  