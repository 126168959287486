.plant-list {
    display: flex;
    overflow-x:auto;
    max-width: 85%;


}

.plant-item {
    display: flex;
    justify-content: center; /* horizontally center */
    align-items: center;     /* vertically center */
    background-color: rgba(50, 245, 169, 0.3);
    color: white;
    border-radius: 50%;      /* makes it a circle */
    width: 60px;
    height: 60px;
    margin-right: 10px;
    font-weight: bold;
    flex-shrink: 0;          /* ensures it doesn't shrink in a flex container */
    cursor: pointer;
}
